<template>
  <div class="church-presentation-template">
    <page-body class>
      <section class="section-1 bg-block mb-4">
        <b-container class="">
          <b-row class="">
            <b-col cols="12">
              <h1 class="sm">{{ translations.tcReviewSubmit }} &mdash; {{ conversation.selectedStateCountry.text }}</h1>
                  <ConversationPageNavigation :i18n="translations.components" :page="currentPage">
                  </ConversationPageNavigation>
                  <hr />
                </b-col>
              </b-row>
            </b-container>
            <b-form @submit.prevent class="view-1 g-mb-2">
              <b-container>
                <b-row class="mb-2">
                  <b-col class="dateTime">
                    <div class="h-style-1">{{ translations.tcEventType }}</div>
                    {{ conversation.eventType ? eventTypeTranslated.find(element => element.value ===
                      conversation.eventType).text : '' }}
                    <div class="h-style-1">{{ translations.tcEventTitle }}</div>
                    <span class="">{{ conversation.eventTitle }}</span>
                    <div class="h-style-1">{{ translations.tcEventDays }}</div>
                    <div class="calendar" v-if="!!conversation.startEndDate">
                      <iCalendar />
                      {{
                        new Date(conversation.startEndDate.startDate + 'T01:02:03').toLocaleString(this.prefCulture, {
                          dateStyle: 'long'
                        })
                      }}
                      <span v-if="conversation.startEndDate.startDate !== conversation.startEndDate.endDate">&nbsp;--
                        {{
                          new Date(conversation.startEndDate.endDate + 'T01:02:03').toLocaleString(this.prefCulture, {
                            dateStyle: 'long'
                          })
                        }}</span>
                    </div>
                    <div class="calendar" v-if="!!conversation.startTime">
                      <iClock class="clockIcon"></iClock>
                      {{ formatTimeString(conversation.startTime) }} -- {{ formatTimeString(conversation.endTime) }}
                    </div>
                  </b-col>
                  <b-col>
                    <div class="h-style-1">{{ translations.tcGeographicDistribution }}</div>
                    <span class="bold-text">{{ conversation.distributionSelected && conversation.distributionSelected.text || '' }}</span>
                    <div v-if="conversation.distributionSelected && conversation.distributionSelected.key === 'areas'">
                      <div v-for="area in conversation.selectedAreas" :key="area.org_key">
                        {{ area.org_name }}
                      </div>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col>
                    <div class="h-style-1">{{ translations.tcEducationalUnit }}</div>
                    {{ conversation.educationalUnitKey ? educational_units.find(element => element.ind_key === conversation.educationalUnitKey).name : translations.tcNotEntered }}
                    <div class="h-style-1">{{ translations.tcInstructor }}</div>
                    {{ conversation.instructorKey ? [...instructors, ...educational_units].find(element => element.ind_key ===
                      conversation.instructorKey).name : translations.tcNotEntered }}
                  </b-col>
                  <b-col>
                    <div class="h-style-1">{{ translations.tcLocalContact }}</div>
                    <span>{{ conversation.localContact && conversation.localContact.value ? conversation.localContact.text : translations.tcNotEntered }}</span>
                    <div class="h-style-1">{{ translations.tcLocation }}</div>
                    <span v-html="locationDisplay"></span>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <div class="h-style-1">{{ translations.tcAttendees }}</div>
                  </b-col>
                </b-row>
                <b-row class="mb-2">
                  <b-col>
                    <span class="bold-text">{{ translations.tcMembers }}</span>
                    <div v-if="conversation.attendeesMembers.length > 0">
                      <div v-for="member in conversation.attendeesMembers" :key="member.value">
                        {{ member.text }}
                      </div>
                    </div>
                    <div v-else>
                      <span class="italic-text">{{ translations.tcNoneSelected }}</span>
                    </div>
                  </b-col>
                  <b-col>
                    <span class="bold-text">{{ translations.tcNonMembers }}</span>
                    <div v-if="conversation.attendeesNonmembers.length > 0">
                      <div v-for="nonmember in conversation.attendeesNonmembers" :key="nonmember.value">
                        {{ nonmember.text }}
                      </div>
                    </div>
                    <div v-else>
                      <span class="italic-text">{{ translations.tcNoneSelected }}</span>
                    </div>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col class="mt-3">
                    <b-button variant="primary" class="mr-3" @click="handleBackClick()">
                      {{ translations.tcBack }}
                    </b-button>
                    <b-button variant="primary" class="mr-3" @click="handleSaveClick()">
                      {{ translations.tcSubmit }}
                    </b-button>
                    <b-button variant="tertiary" @click="handleCancelClick()">{{ translations.tcCancel }}</b-button>
                  </b-col>
                </b-row>
              </b-container>
            </b-form>
            <section class="form-buttons"></section>
          </section>
        </page-body>
      </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import { calendarMixin } from '@/mixins/calendarMixin'
import constantData from '@/json/data.json'
import ConversationPageNavigation from './conversation-page-navigation.vue'
import iCalendar from '@/assets/svgs/iCalendar.vue'
import iClock from '@/assets/svgs/iClock.vue'
import { numericMixin } from '@/mixins/numericMixin'
import pageBody from '@/components/page-components/PageBody.vue'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'add-conversation-review',
  mixins: [translationMixin, calendarMixin, numericMixin],
  data() {
    return {
      activeNext: false,
      availableMembers: [],
      currentPage: 4,
      isEditMode: false,
      translations: {}
    }
  },
  async created() {
    try {
      this.setLoadingStatus(true)
      await Promise.all([
        this.getViewTranslations(),
        this.getComponentTranslations('conversation-page-navigation')
        //this.getComponentTranslations('common.calendar-event-type-conversation')
      ]).then(results => {
        this.stripReadableText(results[2])
        const translatedText = {
          ...results[1],
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)
        //this.loadTranslations()
      })
      await this.page_load()
    } catch (e) {
      console.error(e)
    } finally {
      this.setLoadingStatus(false)
    }
  },
  methods: {
    ...mapActions({
      clearConversation: 'conversations/clearConversation',
      saveConversation: 'conversations/saveConversation',
      setLoadingStatus: 'menu/setLoadingStatus'
    }),
    async page_load() {
      try {
        this.setLoadingStatus(true)
      } catch (e) {
        console.error(e)
      } finally {
        this.setLoadingStatus(false)
      }
    },
    async handleBackClick() {
      this.$router.push({ name: 'conversations-add-attendees' })
    },
    handleCancelClick() {
      this.$swal({
        icon: 'question',
        showCancelButton: true,
        cancelButtonColor: '#d33',
        text: this.translations.tcOkayToCancel,
        confirmButtonText: this.translations.tcOk,
        cancelButtonText: this.translations.tcCancel
      }).then(result => {
        if (result.value) {
          this.clearConversation()
          this.$router.push({ name: 'conversations-landing-page' })
        }
      })
    },
    async handleSaveClick() {
      this.setLoadingStatus(true)
      let preppedConversation = this.prepConversation(this.conversation)
      let result = await this.saveConversation(preppedConversation)
      this.setLoadingStatus(false)

      await this.$swal({
        icon: result ? 'success' : 'error',
        text: result ? this.translations.tcSaveSuccessful : this.translations.tcSaveFailed,
        confirmButtonText: this.translations.tcOk
      }).then((rtn) => {
        if (result) {
          this.$router.push({ name: 'conversations-landing-page' })
          this.clearConversation()
        }
      })
    },
    prepConversation(conversation) {
      const ignoreFields = ({ attendeesMembers, attendeesNonmembers, distributionSelected, localContact, documents, ...rest }) => rest
      const newCon = { ...ignoreFields(conversation) }
      let formData = new FormData()
      formData.append('conversation', JSON.stringify(newCon))
      formData.append('conversation_key', conversation.conversationKey)
      formData.append('mtg_key', conversation.meeting_key)
      formData.append('start_date', conversation.startEndDate.startDate)
      formData.append('end_date', conversation.startEndDate.endDate)
      formData.append('mtg_title', conversation.eventTitle)
      formData.append('mtg_start_time', conversation.startTime)
      formData.append('mtg_end_time', conversation.endTime)
      formData.append('mtg_location_org_key', conversation.location ? conversation.location.org_key : null)
      formData.append('mtg_location_virtual_key', conversation.location_virtual ? conversation.location_virtual.location_virtual_key : null)
      formData.append('mtg_mpt_key', conversation.memberTypeSelected)
      formData.append('mtg_mtt_key', conversation.eventType)
      // formData.append('mtg_mts_key', conversation.)
      let distribution = ''
      if (conversation.distributionSelected.key === 'areas' && conversation.selectedAreas.length > 0) {
        let sas = conversation.selectedAreas.map(item => {
          return `${item.region_key}/${item.org_key}`
        })
        distribution = sas.join()
      } else {
        distribution = `${conversation.org_key}/${conversation.org_key}`
      }
      formData.append('distribution', distribution)
      formData.append('org_key', conversation.org_key)
      formData.append('mtg_mts_key', conversation.meeting_status)
      formData.append('local_contact_ind_key', conversation.localContact ? conversation.localContact.value : null)
      formData.append('educational_unit_ind_key', conversation.educationalUnitKey)
      formData.append('instructor_ind_key', conversation.instructorKey)

      formData.append('attendees_members', conversation.attendeesMembers.length > 0 ? conversation.attendeesMembers.map(item => { return item.value }).join() : '')
      formData.append('attendees_nonmembers', conversation.attendeesNonmembers.length > 0 ? conversation.attendeesNonmembers.map(item => { return item.value }).join() : '')
      formData.append('attendees_members_remove', conversation.attendeesMembers_Remove.length > 0 ? conversation.attendeesMembers_Remove.map(item => { return item.value }).join() : '')
      formData.append('attendees_nonmembers_remove', conversation.attendeesNonmembers_Remove.length > 0 ? conversation.attendeesNonmembers_Remove.map(item => { return item.value }).join() : '')

      return formData

    }
  },
  computed: {
    ...mapGetters({
      conversation: 'conversations/conversation',
      demograph: 'user/demograph',
      educational_units: 'conversations/educational_units',
      instructors: 'conversations/instructors',
      meeting_types: 'conversations/meeting_types',
      officerToolbarSelected: 'user/officerToolbarSelected',
      prefCulture: 'user/userPreferredCulture',
      selectedConversationKey: 'user/userSelectedConversationKey',
      userCountryKey: 'user/userCountryKey'
    }),
    eventTypeTranslated() {
      let options = this.meeting_types.map(item => {
        let text = item.mtt_description_text
        if (!!this.translations.common && !!this.translations.common['calendar-event-type-conversation']) {
          text = this.translations.common['calendar-event-type-conversation'][item.mtt_key] || item.mtt_description_text
        }
        const translatedItem = { value: item.mtt_key, text: text }
        return translatedItem
      })
      return options
    },
    locationDisplay() {
      if (!this.conversation.location && !this.conversation.virtualLocation) {
        return this.translations.tcNotEntered
      }
      if (this.conversation.location && this.conversation.location.org_name) {
        return this.conversation.location.org_name
      } else {
        return this.conversation.virtualLocation.lvt_name + '<br>' + this.conversation.virtualLocation.lvt_note
      }
    },
  },
  watch: {
    // distributionSelected(newval, oldval) {
    //   if (newval.selected) {
    //     if (newval.key === 'full') {
    //       this.isSelectedAreas = false
    //       this.activeNext = false
    //     } else {
    //       this.isSelectedAreas = true
    //       this.activeNext = !this.selectedAreas.length > 0
    //     }
    //   }
  },
  components: {
    ConversationPageNavigation,
    iCalendar: iCalendar,
    iClock: iClock,
    pageBody
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';
@import '@/styles/bootstrap/b-button-group.scss';

.page-body {
  padding: 80px 15px;
}

.bg-block {
  padding: 24px;
}

.bold-text {
  font-weight: bold;
}

.italic-text {
  font-style: italic;
}

.dateTime {
  div.calendar {
    display: flex;
    align-items: center;

    svg.clockIcon {
      margin-right: 15px;

      path {
        fill: #636363 !important;
      }
    }
  }
}

.calendar {
  display: flex;
  align-items: center;

  svg {
    margin-right: 15px;

    path {
      fill: #636363 !important;
    }
  }

  .clockIcon {
    margin-right: 15px;

    path {
      fill: #636363 !important;
    }
  }
}
</style>
